import clsx from 'clsx'
import Link from 'next/link'
import { useSelect } from 'downshift'
import { ReactComponent as ChevronDownIcon } from '@brand/icons/chevron-down.svg'
import { ReactComponent as ChevronUpIcon } from '@brand/icons/chevron-up.svg'
import { IconButton } from '../../../../../components/icon-button/icon-button'
import { type NavLink } from '../../../../../types'
import styles from './manage-rentals-dropdown.module.css'

export type ManageRentalsDropdownProps = {
  options: NavLink[]
  className: string
}

export function ManageRentalsDropdown({
  options,
  className,
}: ManageRentalsDropdownProps) {
  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    items: options,
    itemToString: (item) => item?.linkDescription ?? '',
  })

  return (
    <li className={clsx(className, styles.manageRentalsDropdown)}>
      <IconButton
        {...getToggleButtonProps({
          role: undefined,
        })}
        icon={
          isOpen ? (
            <ChevronUpIcon
              aria-hidden
              className={styles.manageRentalsDropdownIcon}
            />
          ) : (
            <ChevronDownIcon
              aria-hidden
              className={styles.manageRentalsDropdownIcon}
            />
          )
        }
        label="Manage Rentals"
        data-tag_item="manage_rentals"
        showLabel
        iconPosition="right"
        data-tid="manage-rentals-dropdown-button"
      />

      <div
        {...getMenuProps({
          role: undefined,
        })}
        className={clsx(
          styles.manageRentalsDropdownItems,
          !isOpen && styles.hidden
        )}
      >
        {isOpen &&
          options?.map((item, index) => (
            <Link
              key={`sub-item-${item.tagItem}`}
              {...getItemProps({
                item,
                index,
              })}
              target="_blank"
              href={item.href}
              className={clsx(
                styles.manageRentalsDropdownItem,
                highlightedIndex === index &&
                  styles.manageRentalsDropdownItemActive
              )}
              data-tag_item={item.tagItem}
              data-tid={item.tagItem}
            >
              {item.linkDescription}
              <div className={styles.navSubItemLabel}>{item.label}</div>
            </Link>
          ))}
      </div>
    </li>
  )
}
