'use client'

import { useCallback, useRef } from 'react'
import { yieldOrContinue } from 'main-thread-scheduling'
import { ReactComponent as CloseIcon } from '@brand/icons/close.svg'
import { ReactComponent as MenuIcon } from '@brand/icons/menu.svg'
import { hamburgerMenuLinks } from '@brand/config/nav'
import { IconButton } from '../../../../../components/icon-button/icon-button'
import type { DrawerMenu_QueryFragment } from '../../../../../features/page-header/drawer-menu/__generated__/drawer-menu.gql'
import { DrawerMenuSubMenu } from '../../../../../features/page-header/drawer-menu/drawer-menu-sub-menu'
import { DrawerMenuUserLinks } from '../../../../../features/page-header/drawer-menu/user-links'
import { SavedPropertiesLink } from '../../../../../features/page-header/drawer-menu/saved-properties-link'
import { SavedSearchesLink } from '../../../../../features/page-header/drawer-menu/saved-searches-link'
import { DialogModal } from '../../../../../features/dialog-modal/dialog-modal'
import { DialogModalCloseButton } from '../../../../../features/dialog-modal/dialog-modal-close-button'
import styles from './drawer-menu.module.css'

export type DrawerMenuOwnProps = {
  marketTrendsUrl?: string | null
}

export type DrawerMenuProps = DrawerMenuOwnProps & DrawerMenu_QueryFragment

const { manageRentalsLinks, rentalPriceEstimator, researchLink } =
  hamburgerMenuLinks

const links = {
  ['manage rentals']: manageRentalsLinks,
}

export function DrawerMenu(props: DrawerMenuProps) {
  const ref = useRef<HTMLDialogElement>(null)
  const subMenuRef = useRef<HTMLDialogElement>(null)

  const handleOpenMenu = useCallback(async function handleOpenMenu() {
    await yieldOrContinue('idle')
    ref.current?.showModal()

    await yieldOrContinue('smooth')
    window.eventTracker?.track('click', {
      section: 'menu',
      item: 'hamburger',
    })
  }, [])

  const handleCloseMenu = useCallback(async function handleCloseMenu() {
    await yieldOrContinue('interactive')
    ref.current?.close()
  }, [])

  const handleAfterCloseMenu = useCallback(
    async function handleAfterCloseMenu() {
      await yieldOrContinue('smooth')
      subMenuRef.current?.close()
    },
    [subMenuRef]
  )

  return (
    <>
      <button
        data-tid="drawer-menu-open-btn"
        aria-label="Open main navigation menu"
        className={styles.hamburgerButton}
        onClick={handleOpenMenu}
        type="button"
        data-tag_action="ignore"
      >
        <MenuIcon aria-hidden className={styles.hamburgerIcon} />
      </button>
      <DialogModal
        ref={ref}
        entranceAnimation="slide-from-left"
        className={styles.dialog}
        id="hamburger-menu"
        onAfterClose={handleAfterCloseMenu}
      >
        <nav
          aria-label="Main navigation menu"
          className={styles.menu}
          data-tid="drawer-menu"
        >
          <header className={styles.header}>
            <DialogModalCloseButton ref={ref} data-tag_section="menu">
              <IconButton
                data-tid="drawer-menu-close-btn"
                className={styles.closeButton}
                icon={<CloseIcon aria-hidden="true" />}
                id="hambuger-menu-close-btn"
                label={
                  <>
                    Close<span className="sr-only"> navigation menu</span>
                  </>
                }
                variant="anchor"
                showLabel
                iconPosition="right"
                type="button"
              />
            </DialogModalCloseButton>
          </header>

          <div className={styles.body}>
            <ul className={styles.list} id="drawer-menu-list">
              <li>
                <a href="/" data-tag_item="home" className={styles.item}>
                  Home
                </a>
              </li>

              <li>
                <a href="/" data-tag_item="search" className={styles.item}>
                  Search
                </a>
              </li>

              <li>
                <SavedPropertiesLink text="Saved Properties" />
              </li>

              <li>
                <SavedSearchesLink onClick={handleCloseMenu} />
              </li>

              {props.marketTrendsUrl && (
                <li>
                  <a
                    data-tag_item="market_trends"
                    href={props.marketTrendsUrl}
                    className={styles.item}
                  >
                    {[props.location?.city, props.location?.stateAbbr]
                      .filter(Boolean)
                      .join(', ')}{' '}
                    Market Trends
                  </a>
                </li>
              )}
              <li>
                <a
                  href="/blog/moving-center/"
                  data-tag_item="moving_center"
                  target="_blank"
                  className={styles.item}
                >
                  Moving Center
                </a>
              </li>

              <li>
                <a
                  href="/list"
                  data-tag_item="list_a_property"
                  target="_blank"
                  className={styles.item}
                >
                  List a Property
                </a>
              </li>

              {manageRentalsLinks && (
                <DrawerMenuSubMenu ref={subMenuRef} links={links} />
              )}

              <li>
                <a
                  href="https://solutions.rent.com"
                  data-tag_item="business_solutions"
                  className={styles.item}
                  target="_blank"
                  rel="noreferrer"
                >
                  Business Solutions
                </a>
              </li>

              <li>
                <a
                  href="/blog/rent-calculator"
                  data-tag_item="rent-calculator"
                  target="_blank"
                  className={styles.item}
                >
                  Rent Calculator
                </a>
              </li>

              {rentalPriceEstimator && (
                <li>
                  <a
                    href={rentalPriceEstimator.url}
                    data-tag_item={rentalPriceEstimator.dataTagItem}
                    data-tid="rent-estimator-nav-link"
                    target={rentalPriceEstimator.target}
                    className={styles.item}
                  >
                    {rentalPriceEstimator.displayName}
                  </a>
                </li>
              )}

              <li>
                <a
                  href="/blog/"
                  data-tag_item="blog"
                  target="_blank"
                  className={styles.item}
                >
                  Blog
                </a>
              </li>

              {researchLink && (
                <li>
                  <a
                    href={researchLink.url}
                    data-tag_item={researchLink.dataTagItem}
                    target={researchLink.target}
                    className={styles.item}
                  >
                    {researchLink.displayName}
                  </a>
                </li>
              )}

              <li className={styles.divider}>
                <a
                  href="/mobile-apps"
                  data-tag_item="download_apps"
                  className={styles.item}
                >
                  Download the App
                </a>
              </li>

              <DrawerMenuUserLinks handleClose={handleCloseMenu} />
            </ul>
          </div>
        </nav>
      </DialogModal>
    </>
  )
}
