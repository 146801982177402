import type { DetailPageBrandText } from '../../../features/detail/detail-page.types'
/**
 * @file Dynamic text for the AG brand.
 * To add or modify:
 * - AG and Rent files must contain same keys
 * - Update the TypeScript types first (to add type checks for both AG and Rent)
 * - You can use a plain string for simple text substitution, or a function to insert values into the string.
 * - Avoid putting business logic into the functions, this should only be used for formatting text.
 * - This file is for plain text only. For JSX or HTML, use slots instead.
 */

export const showSubheader = true

export const detailPageBrandText: DetailPageBrandText = {
  '3Dtour': '3D Tours',
  hotDealsSectionName: 'Specials',
  hotDealsBadge: 'Deals',
  highlightsSectionName: 'Top Features',
  tourSectionName: '3D Tours & Videos',
  amenitiesSectionName: 'Amenities & Features',
  gettingAroundSectionName: 'Transportation Options',
  internetProvidersSectionName: 'Internet Providers',
  petPolicySectionName: (_propertyName: string) => 'Pet Policy',
  petPolicyAnchorNavLink: 'Pet Policy',
  petPolicyInitialFeeLabel: 'Initial Fee',
  petPolicyMaximumPetsLabel: 'Maximum pets',
  petPolicyMaximumWeightLabel: 'Maximum weight',
  petPolicyAdditionalRentLabel: 'Additional rent',
  petPolicyDepositLabel: 'Deposit',
  placesNearbySectionName: 'Nearby Places',
  priceDropLabel: 'Price Drop',
  ratingReviewSectionName: 'Ratings & Reviews',
  ratingReviewInternalLabel: 'Rent.',
  ratingReviewSubheadingWithReview: 'See what people are saying',
  getTourSectionSubheading: (propertyName: string) =>
    `Discover what ${propertyName} has to offer`,
  getAmenitiesSectionSubheading: (amenityCount) =>
    `${amenityCount} Amenities & Features`,
  getGettingAroundSectionSubheading: ({ address, walkScore }) =>
    `${address} is: ${walkScore}`,
  formatBedText: (originalBedText: string) => originalBedText,
  formatBathText: (originalBathText: string) => originalBathText,
  totalCostAndFeesSectionName: 'Total Costs & Fees',
  offMarketBadgeText: 'Previously Available for Rent',
}
