/* eslint-disable react/jsx-no-target-blank */
import { useCallback, forwardRef, useImperativeHandle, useRef } from 'react'
import { yieldOrContinue } from 'main-thread-scheduling'
import clsx from 'clsx'
import { ReactComponent as ArrowLeftIcon } from '@brand/icons/arrow-left.svg'
import { ReactComponent as ChevronRightIcon } from '@brand/icons/chevron-right.svg'
import { IconButton } from '../../../components/icon-button/icon-button'
import type { HamburgerMenuLink } from 'types'
import type { ForwardedRef } from 'react'
import styles from '@brand/slots/page-header/drawer-menu/drawer-menu.module.css'

type DrawerMenuSubMenuProps = {
  links: Record<string, HamburgerMenuLink[] | null>
}

export const DrawerMenuSubMenu = forwardRef(function DrawerMenuSubMenu(
  props: DrawerMenuSubMenuProps,
  forwardedRef: ForwardedRef<HTMLDialogElement>
) {
  const ref = useRef<HTMLDialogElement>(null)

  useImperativeHandle(forwardedRef, () => ref.current!)

  const handleOpenSubMenu = useCallback(async function handleOpenSubMenu() {
    await yieldOrContinue('idle')
    // use show() instead of showModal() so it's non-modal
    // this is a sub menu of main menu which is already a dialog modal
    ref.current?.show()

    await yieldOrContinue('smooth')
    window.eventTracker?.track('click', {
      section: 'menu',
      item: 'hamburger',
    })
  }, [])

  const handleCloseSubMenu = useCallback(async function handleCloseSubMenu() {
    await yieldOrContinue('interactive')
    ref.current?.close()
  }, [])

  return (
    <>
      {Object.keys(props.links || {}).map((linkKey, i) => {
        const links = props.links[linkKey]
        if (!links || links.length === 0) {
          return null
        }

        return (
          <li key={linkKey + i}>
            <button
              type="button"
              className={clsx(styles.item, styles.capitalize)}
              data-tag_item={linkKey}
              data-tid={linkKey}
              onClick={handleOpenSubMenu}
            >
              {linkKey} <ChevronRightIcon className={styles.nextIcon} />
            </button>
            <dialog ref={ref} className={styles.submenu}>
              <header className={clsx(styles.header, styles.leftAlign)}>
                <IconButton
                  variant="anchor"
                  icon={<ArrowLeftIcon aria-hidden="true" />}
                  label={
                    <>
                      <span className="sr-only"> Back to</span> Main Menu
                    </>
                  }
                  showLabel
                  iconPosition="left"
                  onClick={handleCloseSubMenu}
                  type="button"
                />
              </header>

              <ul className={clsx(styles.body, styles.list)}>
                {links.map((link, j) => (
                  <li key={link.displayName + j}>
                    <a
                      className={clsx(
                        styles.item,
                        link.linkDescription && styles.itemWithDescription
                      )}
                      href={link.url}
                      data-tag_item={link.dataTagItem || link.displayName}
                      data-tid={link.dataTagItem || link.displayName}
                      target={link.target || '_self'}
                      rel={link.ref || undefined}
                    >
                      {link.linkDescription && (
                        <span className={styles.description}>
                          {link.linkDescription}
                        </span>
                      )}
                      {link.displayName}
                    </a>
                  </li>
                ))}
              </ul>
            </dialog>
          </li>
        )
      })}
    </>
  )
})
